<template>
  <v-card class="mb-2">
    <v-card-text>
      <v-row>
        <v-col>
          <v-autocomplete v-model="cert" :items="certItems" :rules="required" label="Certificate"></v-autocomplete>
        </v-col>
        <v-col>
          <v-select v-model="advisor" :items="advisorItems" :readonly="advisorItems.length <= 2" :persistent-hint="advisorItems.length === 2" :hint="advisorItems.length === 2 ? 'Only advisor for this certificate' : ''" label="Requested Advisor">
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon color="red" @click="$emit('remove')">
                    <v-icon>fal fa-times-circle</v-icon>
                  </v-btn>
                </template>
                <span>Remove this certificate</span>
              </v-tooltip>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'
import { certificates, certAdvisors } from './options'

export default {
  props: {
    certificate: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const cert = ref(props.certificate.cert || '')
    const advisor = ref(props.certificate.advisor || '')

    watch(() => props.certificate, () => {
      cert.value = props.certificate.cert
      advisor.value = props.certificate.advisor
    })

    const certItems = computed(() => certificates)
    const advisorItems = computed(() => {
      const arr = [{ value: '', text: '**No Preference**' }]
      if (cert.value in certAdvisors) certAdvisors[cert.value].forEach((row) => arr.push(row))
      return arr
    })
    watch(advisorItems, () => {
      if (advisorItems.value.length === 2) {
        advisor.value = advisorItems.value[1].value
      } else {
        let included = false
        for (let i = 0; i < advisorItems.value.length; i++) {
          if (advisor.value === advisorItems.value[i].value) included = true
        }
        if (!included && advisor.value !== '') advisor.value = ''
      }
    })

    const required = ref([(v) => !!v || 'Required'])

    watch([cert, advisor], () => {
      emit('update', { cert: cert.value, advisor: advisor.value })
    })

    return {
      cert,
      advisor,
      certItems,
      advisorItems,
      required
    }
  }
}
</script>
